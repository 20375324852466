<template>
    <div class="wrapper">
         <img class="logo" src="https://static.yihu365.cn/img/h5Img/assets/img/logo2.png" alt="">
         <div class="content">
             <img src="https://static.yihu365.cn/img/h5Img/assets/img/content.png" alt="">
         </div>
    </div>
</template>

<script>
    export default {
        name: "ybActivity"
    }
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
   .wrapper{
       position: relative;
       width: 100%;
       height:100%;
       box-sizing: border-box;
       background:url("https://static.yihu365.cn/img/h5Img/assets/img/bg_10.21.png") no-repeat;
       background-size: 100% 100%;
       .logo{
           width: 200px;
           position: absolute;
           left:50px;top:50px;
       }
       .content{
           position: absolute;
           left:0;top:0;
           width: 100%;
           img{
               width: 100%;
           }
       }
   }
</style>
