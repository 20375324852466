import { render } from "./ybActivity.vue?vue&type=template&id=e40bc822&scoped=true"
import script from "./ybActivity.vue?vue&type=script&lang=js"
export * from "./ybActivity.vue?vue&type=script&lang=js"

import "./ybActivity.vue?vue&type=style&index=0&id=e40bc822&rel=stylesheet%2Fscss&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-e40bc822"
/* hot reload */
if (module.hot) {
  script.__hmrId = "e40bc822"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('e40bc822', script)) {
    api.reload('e40bc822', script)
  }
  
  module.hot.accept("./ybActivity.vue?vue&type=template&id=e40bc822&scoped=true", () => {
    api.rerender('e40bc822', render)
  })

}

script.__file = "src/views/static/about/activity/ybActivity.vue"

export default script